import React from 'react'
import css from './AccountDownloadButtons.module.scss'

const AccountDownloadButtons = () => {
  return (
    <ul className={css.downloadButtons}>
      <li>
        <a
          href="https://apps.apple.com/us/app/alive-by-whitney-simmons/id1488020055"
          target="_blank"
        >
          <img
            width="120"
            height="40"
            src="/img/btn-app-store.png"
            alt="Alive app on App Store"
          />
        </a>
      </li>
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=com.cc.alive&hl=en_US"
          target="_blank"
        >
          <img
            width="135"
            height="40"
            src="/img/btn-google-play.png"
            alt="Alive app on Google Play"
          />
        </a>
      </li>
    </ul>
  )
}

export default AccountDownloadButtons
