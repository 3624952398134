import currency from 'currency.js'
import { colors } from './colors'
import { StripeElementsOptions } from '@stripe/stripe-js'

export const formatPrice = (price: number, divide = 1): currency => {
  if (price === 0) return currency(price)
  return currency(price, { fromCents: true, precision: 2 }).divide(divide)
}

export const elementsAppearance: StripeElementsOptions = {
  appearance: {
    theme: 'none',
    variables: {
      borderRadius: '5px',
      // spacingUnit: 'px',
      fontFamily: 'Campton, Questrial, serif',
      // colorPrimary?: string;
      // colorPrimaryText?: string;
      colorBackground: '#EEF4FF',
      // colorBackgroundText?: string;
      colorText: '#0D66FC',
      // colorSuccess?: string;
      // colorSuccessText?: string;
      colorDanger: colors.red,
      colorDangerText: colors.red,
      // colorWarning?: string;
      // colorWarningText?: string;
    },
    rules: {
      '.Input': {
        padding: '16px',
        margin: '0 0 10px 0',
      },
      '.Label': {
        padding: '0',
        margin: '0 0 16px 0',
        color: 'black',
      },
      '.Input:focus': {
        boxShadow:
          '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorPrimary)',
      },
      '.Input::placeholder': {
        color: colors.blue30,
      },
      '.TermsText': {
        color: 'black',
      },
    },
  },
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Questrial' }],
}
