import { HTMLProps } from 'react'
import {
  FormProvider,
  SubmitHandler,
  FieldValues,
  ErrorOption,
  FormProviderProps,
} from 'react-hook-form'

interface CustomFormProps<TFieldValues extends FieldValues = FieldValues> {
  className?: string
  loading?: boolean
  onSubmit: SubmitHandler<TFieldValues>
  error?: { message: string } | ErrorOption
}

type InnerFormProps<TFieldValues extends FieldValues = FieldValues> =
  FormProviderProps & CustomFormProps

export const Form = <TFormValues extends any>({
  onSubmit,
  className,
  children,
  ...rest
}: InnerFormProps) => {
  return (
    <FormProvider {...rest}>
      <form className={className} onSubmit={rest.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  )
}

interface FormErrorProps extends HTMLProps<HTMLDivElement> {
  error: ErrorOption | { message: string }
}

export const FormError = ({ error, className, ...rest }: FormErrorProps) => (
  <div className={`caption text-red ${className ? className : ''}`} {...rest}>
    {error ? error.message : null}
  </div>
)

export type FormProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  InnerFormProps<TFieldValues>,
  'children'
>
