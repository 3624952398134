import React, {useCallback, useState} from 'react'
import {useMst} from '~stores'
import {observer} from 'mobx-react'
import {getValidationSchema, SentryCaptureException} from '~utils'
import {ModalProps} from '~components/Modal/Modal'
import {requiredEmail} from '~validations'
import {
    useCustomForm,
    Button,
    Typography,
    Modal,
    Form,
    Input,
    ControlledInput,
} from '~components'
import {Notify} from 'notiflix'
import {ForgotPasswordForm} from '~components/pages'

type AccountLoginModalProps = Omit<ModalProps, 'children'>

interface FormSchema {
    email: string
}

const schema = getValidationSchema({
    email: requiredEmail,
})

const AccountLoginModal: React.FC<AccountLoginModalProps> = ({
                                                                 onClose,
                                                                 open,
                                                             }) => {
    const {userStore} = useMst()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState<'initial' | 'password' | 'password-success'>('initial')

    const [pwdFormError, setPwdFormError] = useState<{ message: string }>()
    const [pwdLoading, setPwdLoading] = useState(false)

    const context = useCustomForm({
        schema,
        defaultValues: {
            email: userStore.userData.email,
        },
    })

    const renderContent = useCallback(() => {
        const onSubmit = async (data: FormSchema) => {
            setLoading(true)
            try {
                await userStore.patchUserData(data)
                Notify.success('Your email has been saved successfully.')
                onClose()
            } catch (e) {
                Notify.failure('There was an error, try again later.')
            } finally {
                setLoading(false)
            }
        }

        const onPasswordSubmit = async ({email}) => {
            setPwdLoading(true)
            try {
                await userStore.authResetPasswordRequest(email)
                setStatus('password-success')
                // Change content
            } catch (e) {
                if (e.response.status === 404) {
                    setStatus('password-success')
                } else {
                    setPwdFormError({
                        message: 'An error occurred, try again later.',
                    })
                    SentryCaptureException(e)
                }
            } finally {
                setPwdLoading(false)
            }
        }

        switch (status) {
            case 'initial':
                return (
                    <>
                        <Typography.Title level={'h3'} className={'text-blue mb-3'}>
                            Account log in
                        </Typography.Title>
                        <Form {...context} onSubmit={onSubmit}>
                            <ControlledInput
                                name={'email'}
                                inputProps={{
                                    label: 'Your Email',
                                    placeholder: 'mail@example.com',
                                    wrapperClassName: 'mb-3',
                                    theme: 'white',
                                }}
                                transform={{
                                    output: (e) => {
                                        return e.target.value.toLowerCase()
                                    },
                                }}
                            />
                            <Input
                                name={'line2'}
                                theme={'white'}
                                type={'text'}
                                placeholder={'********'}
                                label={'Password'}
                                disabled
                            />
                            <Typography.Text
                                type={'copy'}
                                className={'link mt-2 mb-4'}
                                onClick={() => setStatus('password')}
                            >
                                Reset Password
                            </Typography.Text>
                            <Button
                                type={'submit'}
                                className={'lg:col-span-4 mb-1'}
                                disabled={!context.formState.isDirty}
                                loading={loading}
                            >
                                Submit
                            </Button>
                        </Form>
                        <Button secondary onClick={onClose}>
                            {' '}
                            Cancel changes
                        </Button>
                    </>
                )
            case 'password':
                return (
                    <>
                        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
                            Reset Password
                        </Typography.Title>
                        <Typography.Text type={'caption'} className={'mb-5'}>
                            {
                                "Please verify your email and we'll send instructions to reset your password."
                            }
                        </Typography.Text>
                        <ForgotPasswordForm
                            loading={pwdLoading}
                            error={pwdFormError}
                            onSubmit={onPasswordSubmit}
                        />
                        <Typography.Text
                            type={'copy'}
                            className={'link mt-5'}
                            onClick={() => setStatus('initial')}
                        >
                            {'<'} &nbsp;&nbsp;&nbsp;Back
                        </Typography.Text>
                    </>
                )
            case 'password-success':
                return (
                    <>
                        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
                            Email has been sent!
                        </Typography.Title>
                        <Typography.Text type={'caption'} className={'mb-5'}>
                            You will receive an email with reset instructions if an account
                            exists with your email.
                        </Typography.Text>
                    </>
                )
        }
    }, [context, loading, onClose, pwdFormError, pwdLoading, status, userStore])

    return (
        <Modal open={open} onClose={onClose}>
            {renderContent()}
        </Modal>
    )
}

export default observer(AccountLoginModal)
