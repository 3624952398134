import React, {useMemo} from 'react'
import {ALIVE_MARKETING_URL, formatPrice} from '~utils'
import {IStripeSubscription, IStripeCustomer} from '~stores'
import {Link, PricingTableItem, Typography} from '~components'

interface AccountSubscriptionProps {
    customer: IStripeCustomer
    subscription: IStripeSubscription
}

const AccountSubscription: React.FC<AccountSubscriptionProps> = ({
                                                                     subscription,
                                                                     customer,
                                                                 }) => {

    const title = useMemo(() => (
        `${subscription.readableInterval} Plan - $${formatPrice(
            subscription.plan.amount,
        )} USD`
    ), [subscription])

    const subtitle = useMemo(() => (
        customer.upcoming_invoice
            ? `${subscription.readablePricing.subtitle}. for $${formatPrice(
                customer.upcoming_invoice.amount_due,
            )} USD.`
            : `${subscription.readablePricing.subtitle}`
    ), [customer, subscription])


    return (
        <>
            <div className={'mb-5 lg:col-span-3'}>
                <PricingTableItem checked title={title} subtitle={subtitle}/>
            </div>

            <Typography.Text type={'label'} className={'mb-2'}>
                Recurring Billing
            </Typography.Text>

            <Typography.Text type={'caption'} className={'mb-2'}>
                This subscription will automatically renew unless you cancel your
                subscription at least 24 hours before current period ends. To cancel
                your subscription click “Manage” and click “Cancel my subscription”.
            </Typography.Text>

            <Link
                href={`${ALIVE_MARKETING_URL}/terms`}
                className={'caption text-blue'}
            >
                Read terms & conditions
            </Link>
        </>
    )
}
export default AccountSubscription
