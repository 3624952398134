import React, { FC, useMemo, useState } from 'react'
import { useMst } from '~stores'
import { Notify } from 'notiflix'
import { CompletedIcon } from '~svg'
import { observer } from 'mobx-react'
import { formatDateNumber } from '~utils'
import {
  Button,
  ButtonGroup,
  Modal,
  PricingTableItem,
  Transition,
  Typography,
  PricingTableRadioGroup,
  LoadingIndicator,
} from '~components'
import { PaymentForm } from '~components/pages'
import type { ModalProps } from '~components'
import { IUserSubscriptionStatus } from '~stores/user'

type AccountSubscriptionModalProps = Omit<ModalProps, 'children'>

type AccountSubscriptionModalStatus =
  | 'loading'
  | 'resubscribe'
  | 'subscription_edit'
  | 'subscription_success'
  | 'cancel_alert'
  | 'payment_card'

const getInitialStatus = (
  status: IUserSubscriptionStatus,
): AccountSubscriptionModalStatus => {
  switch (status) {
    case 'canceled':
    case 'expired':
      return 'resubscribe'
    default:
      return 'subscription_edit'
  }
}

const AccountSubscriptionModal: FC<AccountSubscriptionModalProps> = ({
  onClose,
  open,
}) => {
  const rootStore = useMst()
  const { stripeStore, userStore } = rootStore

  const [status, setStatus] = useState<AccountSubscriptionModalStatus>(
    getInitialStatus(userStore.subscriptionStatus),
  )

  const [selectedPriceId, setSelectedPriceId] = useState<string>(
    status === 'subscription_edit'
      ? stripeStore.subscription.plan.id
      : undefined,
  )

  const loading = useMemo(() => status === 'loading', [status])

  const setSubscriptionByID = async () => {
    if (stripeStore.customer.default_payment_method) {
      setStatus('loading')
      const selectedPrice = stripeStore.getPriceById(selectedPriceId)
      const couponId = selectedPrice.coupon
        ? selectedPrice.coupon.id
        : undefined

      await stripeStore.setSubscriptionById({
        price_id: selectedPriceId,
        coupon_id: couponId,
      })
      await rootStore.hydrate()
      setStatus('subscription_success')
    } else {
      setStatus('payment_card')
    }
  }

  const onSubscriptionChangeSubmit = async () => {
    try {
      await setSubscriptionByID()
    } catch (e) {
      setStatus('subscription_edit')
      Notify.failure('There was an error, try again later.')
    }
  }

  const onCancelSubmit = async () => {
    try {
      setStatus('loading')
      await stripeStore.deleteSubscription(stripeStore.subscription.id)
      await rootStore.hydrate()
      onClose()
      Notify.success('Your subscription was canceled successfully.')
    } catch (e) {
      Notify.failure('There was an error, try again later.')
    }
  }

  const onPaymentCallback = async (success) => {
    if (success) {
      await setSubscriptionByID()
      setStatus('subscription_success')
    } else {
      setStatus('payment_card')
    }
  }

  const onReSubscribePress = async () => {
    try {
      await setSubscriptionByID()
    } catch (e) {
      setStatus('resubscribe')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Transition show={status === 'loading'} className={'flex justify-center'}>
        <LoadingIndicator size={'lg'} text />
      </Transition>

      {/*SUBSCRIPTION EDIT*/}
      <Transition show={status === 'subscription_edit'}>
        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
          My subscription
        </Typography.Title>

        {userStore.subscriptionStatus === 'active' ? (
          <div className={'mb-5'}>
            <Typography.Text as={'h2'} type={'caption'}>
              <strong>iOS & Android Subscriptions</strong>
            </Typography.Text>
            <Typography.Text as={'p'} type={'caption'} className={'mb-2.5'}>
              Changing your subscription type will begin once the old
              subscription has expired. Your changes will officially go into
              effect on{' '}
              <strong>{` ${formatDateNumber(
                stripeStore.subscription.current_period_end,
              )}`}</strong>
              .
            </Typography.Text>

            <Typography.Text as={'h2'} type={'caption'}>
              <strong>Existing Web Subscriptions</strong>
            </Typography.Text>
            <Typography.Text as={'p'} type={'caption'}>
              Changing your subscription type will charge you immediately. For
              more information on your subscription price details,{' '}
              <a
                className={'link'}
                href="https://intercom.help/alive-app/en/articles/8594269-black-friday-2023-discount-details"
              >
                read here.
              </a>
            </Typography.Text>
          </div>
        ) : null}

        <PricingTableRadioGroup
          value={selectedPriceId}
          prices={stripeStore.prices}
          onChange={(id) => setSelectedPriceId(id)}
        />

        <ButtonGroup className={'mt-3'}>
          <Button onClick={onSubscriptionChangeSubmit} disabled={loading}>
            Change My Subscription
          </Button>

          {userStore.subscriptionStatus === 'active' ? (
            <div className={'mt-2 mb-2'}>
              <Button warning onClick={() => setStatus('cancel_alert')}>
                Cancel My Subscription
              </Button>
            </div>
          ) : null}

          <Button secondary onClick={onClose} disabled={loading}>
            Cancel changes
          </Button>
        </ButtonGroup>
      </Transition>

      {/*RE-SUBSCRIPTION*/}
      <Transition show={status === 'resubscribe'}>
        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
          Resubscribe
        </Typography.Title>
        <Typography.Text type={'caption'} className={'mb-5'}>
          Choose your Alive app plan.
        </Typography.Text>

        <div className={'mb-5'}>
          <PricingTableRadioGroup
            value={selectedPriceId}
            prices={stripeStore.prices}
            onChange={(id) => setSelectedPriceId(id)}
          />
        </div>

        <ButtonGroup>
          <Button disabled={!selectedPriceId} onClick={onReSubscribePress}>
            Resubscribe
          </Button>

          <Button secondary onClick={onClose} disabled={loading}>
            Cancel changes
          </Button>
        </ButtonGroup>
      </Transition>

      {/*PAYMENT_CARD*/}
      <Transition show={status === 'payment_card'}>
        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
          {'Payment Information'}
        </Typography.Title>
        {userStore.subscriptionStatus === 'active' ? (
          <Typography.Text type={'caption'}>
            To complete your subscription plan change, please enter your payment
            information.
          </Typography.Text>
        ) : null}

        <div className={'mb-4 mt-5'}>
          <PaymentForm
            white
            beforeSubmit={() => {
              setStatus('loading')
            }}
            submitButtonTexts={{
              submit:
                userStore.subscriptionStatus === 'expired'
                  ? 'Pay'
                  : 'Save card',
              loading:
                userStore.subscriptionStatus === 'expired'
                  ? 'Paying'
                  : 'Saving',
            }}
            onSubmitCallback={onPaymentCallback}
          />
        </div>
      </Transition>

      {/*SUBSCRIPTION WAS UPDATED SUCCESSFULLY*/}
      <Transition show={status === 'subscription_success'}>
        <div className={'flex justify-center mb-3'}>
          <CompletedIcon width={32} />
        </div>
        <Typography.Title level={'h3'} className={'text-blue mb-8'}>
          {`Your subscription has been updated to a ${stripeStore.subscription.readableInterval} Subscription`}
        </Typography.Title>
        <ButtonGroup>
          <Button onClick={onClose}>{'Close'}</Button>
          <Button secondary onClick={() => setStatus('subscription_edit')}>
            Change my subscription
          </Button>
        </ButtonGroup>
      </Transition>

      {/*SUBSCRIPTION CANCELLATION*/}
      <Transition show={status === 'cancel_alert'}>
        <Typography.Title level={'h3'} className={'text-blue mb-1'}>
          Are you sure you want to cancel your subscription?
        </Typography.Title>
        <Typography.Text type={'caption'} className={'mb-5'}>
          You will still have access to the app through.{' '}
          <strong>
            {formatDateNumber(stripeStore.subscription.current_period_end)}
          </strong>
        </Typography.Text>

        <ButtonGroup>
          <Button onClick={onCancelSubmit} disabled={loading}>
            Yes, cancel my subscription.
          </Button>

          <Button
            disabled={loading}
            theme={'inverted'}
            onClick={() => setStatus('subscription_edit')}
          >
            No
          </Button>
        </ButtonGroup>
      </Transition>
    </Modal>
  )
}

export default observer(AccountSubscriptionModal)
