import { FC, HTMLProps } from 'react'
import css from './Card.module.scss'

interface CardProps extends HTMLProps<HTMLDivElement> {
  background?: string
  className?: string
}

const backgroundClassNames = {
  white: 'bg-white',
  lightBlue: 'bg-light-blue',
}

const Card: FC<CardProps> = ({
  children,
  background = 'lightBlue',
  className,
  ...rest
}) => {
  return (
    <div
      className={`${css.default} ${backgroundClassNames[background]} ${
        className ? className : ''
      }`}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Card
