const CheckBox = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#EBFFA1" />
    <path d="M7 13.5L11.5 17.5L19 8" stroke="#0D66FC" strokeWidth="3" />
  </svg>
)

export default CheckBox
