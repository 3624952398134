import React from 'react'
import css from './Footer.module.scss'
import { Instagram, YouTube } from '~svg'
import { Link } from '~components'
import { ALIVE_MARKETING_URL } from '~utils'

interface FooterProps {}

const footer_links = [
  {
    newTab: true,
    label: 'Support',
    link: `${ALIVE_MARKETING_URL}/support`,
  },
  { label: 'Sign In', link: '/login' },
  {
    label: 'Privacy Policy',
    link: `${ALIVE_MARKETING_URL}/privacy`,
  },
  {
    label: 'Terms & Conditions',
    link: `${ALIVE_MARKETING_URL}/terms`,
  },
]

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className={css.default}>
      <div className={css.inner}>
        <div className={css.social}>
          <div className={css.social__item}>
            <Link href={'https://www.instagram.com/thealiveapp/'}>
              <Instagram width={20} height={20} />
            </Link>
          </div>
          <div className={css.social__item}>
            <Link
              href={
                'https://www.youtube.com/channel/UCEQi1ZNJiw3YMRwni0OLsTQ/videos'
              }
            >
              <YouTube width={20} height={20} />
            </Link>
          </div>
        </div>
        <div className={css.link_list}>
          {footer_links.map((item) => (
            <span key={`footer-link-${item.label}`} className={css.link_item}>
              <Link target={item.newTab ? '_blank' : ''} href={item.link}>
                {item.label}
              </Link>
            </span>
          ))}
        </div>
        <div className={css.copyWrite}>&copy; Whitney Simmons App LLC</div>
        <div className={css.break} />
      </div>
    </footer>
  )
}

export default Footer
