import React, { useMemo, useState } from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { getValidationSchema } from '~utils'
import { ModalProps } from '~components/Modal/Modal'
import PhoneInput from '~components/Input/PhoneInput'
import type { UserNoId } from '~api/APITypes'
import type { CountryCode } from 'libphonenumber-js/min'
import { phone, requiredStringMessage } from '~validations'
import {
  useCustomForm,
  Form,
  ControlledInput as Input,
  Button,
  Typography,
  Modal,
} from '~components'
import { parse } from 'libphonenumber-js'
import { Notify } from 'notiflix'

type ProfileFormProps = Omit<ModalProps, 'children'>

interface FormSchema {
  first_name: string
  last_name: string
  mobile: string
}

const AccountProfileModal: React.FC<ProfileFormProps> = ({ onClose, open }) => {
  const { userStore } = useMst()
  const initialPhoneCode = useMemo<CountryCode>(() => {
    if (userStore.userData.mobile)
      return parse(userStore.userData.mobile).country
    else return 'US'
  }, [userStore.userData.mobile])

  const [countryCode, setCountryCode] = useState<CountryCode>(initialPhoneCode)

  const schema = useMemo(
    () =>
      getValidationSchema({
        first_name: requiredStringMessage,
        last_name: requiredStringMessage,
        mobile: phone(countryCode),
      }),
    [countryCode],
  )

  const context = useCustomForm<FormSchema>({
    schema,
    defaultValues: {
      first_name: userStore.userData.first_name,
      last_name: userStore.userData.last_name,
      mobile: userStore.userData.mobile ? userStore.userData.mobile : undefined,
    },
  })

  const onSubmit = async (data: FormSchema) => {
    const patch: UserNoId = {}

    try {
      if (data.first_name !== userStore.userData.first_name)
        patch['first_name'] = data.first_name
      if (data.last_name !== userStore.userData.last_name)
        patch['last_name'] = data.last_name
      if (data.mobile !== userStore.userData.mobile)
        patch['mobile'] = data.mobile

      await userStore.patchUserData(data)
      Notify.success('Saved succesfully')
      onClose()
    } catch (e) {
      Notify.failure('There was an error, try again later.')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Typography.Title level={'h3'} className={'text-blue mb-5'}>
        {'Profile'}
      </Typography.Title>

      <Form {...context} onSubmit={onSubmit}>
        <Input
          name={'first_name'}
          inputProps={{
            label: 'First Name',
            wrapperClassName: 'mb-2',
            theme: 'white',
          }}
        />
        <Input
          name={'last_name'}
          defaultValue={userStore.userData.last_name}
          inputProps={{
            label: 'Last Name',
            wrapperClassName: 'mb-2',
            theme: 'white',
          }}
        />
        <PhoneInput
          name={'mobile'}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          inputProps={{
            label: 'Phone',
            theme: 'white',
          }}
        />
        <Typography.Text type={'caption'} className={'mb-5'}>
          Adding your phone number to your account enables you to login with
          your phone number or email.
        </Typography.Text>
        <Button
          type={'submit'}
          className={'mb-1 mt-5'}
          disabled={!context.formState.isDirty}
        >
          Save Changes
        </Button>
      </Form>
      <Button secondary onClick={onClose}>
        {' '}
        Cancel changes
      </Button>
    </Modal>
  )
}

export default observer(AccountProfileModal)
