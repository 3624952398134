import React, { forwardRef } from 'react'
import { Switch } from '@headlessui/react'
import { ErrorOption } from 'react-hook-form'
import classNames from 'classnames/bind'
import css from './Toggle.module.scss'

const cx = classNames.bind(css)

export interface ToggleProps {
  label?: string
  wrapperClassName?: string
  value?: boolean
  onBlur?: () => void
  onChange?: () => void
  error?: ErrorOption
}

const Toggle = ({
  label,
  wrapperClassName,
  error,
  onChange,
  onBlur,
  value,
}: ToggleProps) => {
  return (
    <div className={classNames(wrapperClassName)}>
      <Switch.Group as="div" className="flex items-start">
        <Switch
          checked={value}
          onBlur={onBlur}
          onChange={onChange}
          className={cx({ default: true, checked: value })}
        >
          <span
            aria-hidden="true"
            className={cx({ ball: true, checked: value })}
          />
        </Switch>
        <Switch.Label as="span" className={css.switch_label}>
          {label}
        </Switch.Label>
      </Switch.Group>
      <span className={'caption text-red'}>{error ? error.message : null}</span>
    </div>
  )
}

Toggle.displayName = 'Toggle'
export default Toggle
