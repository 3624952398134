import React from 'react'
import { Button, Typography } from '~components'
import css from './Table.module.scss'

const TableRowHeader: React.FC<{ items: string[] }> = ({ items }) => (
  <div className={css.rowHeader}>
    {items.map((item, index) => (
      <Typography.Title level={'h4'} key={`${item}-${index}`}>
        {item}
      </Typography.Title>
    ))}
  </div>
)

const handleUrl = (url: string) => {
  window.open(url)
}

export type TableItem = {
  value: string
  header: string
  type?: 'button' | 'text'
  showHeaderOnMobile?: boolean
  className: string
}

const defaultTableItem: TableItem = {
  header: '',
  value: '',
  type: 'text',
  showHeaderOnMobile: false,
  className: '',
}

const TableItem: React.FC<{ item: TableItem }> = (
  { item } = { item: defaultTableItem },
) => {
  if (item.type === 'button') {
    return (
      <div className={item.className}>
        <span className={'desktop-only'}>
          <Button disabled={!item.value} onClick={() => handleUrl(item.value)}>
            View
          </Button>
        </span>
        <span className={'mobile-only'}>
          <Typography.Text
            as={'a'}
            className={'text-blue flex flex-row'}
            href={item.value ? item.value : undefined}
          >
            View <span className={'mobile-only text-blue'}>&nbsp;Receipt</span>
          </Typography.Text>
        </span>
      </div>
    )
  } else {
    return (
      <div className={`my-2 lg:my-0 ${item.className}`}>
        {item.header ? (
          <span className={'h4 mb-1 mobile-only'}>{item.header}</span>
        ) : null}
        <Typography.Title level={'h4'} className={'text-blue'}>
          {item.value}
        </Typography.Title>
      </div>
    )
  }
}

export type TableItems = Array<Array<TableItem>>

interface TableProps {
  items: TableItems
  header: string[]
}

const Table: React.FC<TableProps> = ({ items, header }) => {
  return (
    <div className={css.base}>
      <TableRowHeader items={header} />
      {items.map((row, index) => (
        <div className={css.row} key={`TableItem-Row-${index}`}>
          {row.map((col, idx) => (
            <TableItem key={`TableItem-Col-${col.header}-${idx}`} item={col} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Table
