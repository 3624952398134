import React from 'react'
import css from './Toast.module.scss'

interface ToastProps {}

const Toast: React.FC<ToastProps> = ({ children }) => {
  return <div className={css.wrapper}>{children}</div>
}

export default Toast
