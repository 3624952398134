import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import indicatorData from '~data/loading-indicator.json'
import whiteIndicatorData from '~data/loading-indicator-white.json'
import { Typography } from '~components/index'

interface LoadingIndicatorProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  white?: boolean
  text?: boolean | string
  size?: 'sm' | 'md' | 'lg'
}

const sizes = {
  sm: 8 * 2,
  md: 8 * 5,
  lg: 8 * 10,
}

const Lottie = dynamic(
  () => import('react-lottie-player/dist/LottiePlayerLight'),
  { ssr: false },
)

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: indicatorData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// }

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  size = 'sm',
  white = false,
  text = false,
  className,
  ...rest
}: LoadingIndicatorProps) => {
  const _size = useMemo(() => sizes[size], [size])
  const animationData = useMemo(
    () => (white ? whiteIndicatorData : indicatorData),
    [white],
  )

  return (
    <div
      {...rest}
      style={{ width: _size, height: _size }}
      className={`${className ? className : ''} pointer-events-none`}
    >
      <div style={{ width: _size, height: _size }}>
        <Lottie
          loop
          play
          animationData={animationData}
          style={{ width: _size, height: _size }}
        />
      </div>
      {typeof text === 'string' ? (
        text
      ) : text ? (
        <Typography.Text
          type={'caption'}
          className={'mt-5 text-blue text-center'}
        >
          Loading
        </Typography.Text>
      ) : null}
    </div>
  )
}

export default LoadingIndicator
