import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useMst } from '~stores'
import { Notify } from 'notiflix'
import { Transition, Button, ButtonGroup, Modal, Typography } from '~components'
import { ModalProps } from '~components/Modal/Modal'
import { PaymentForm } from '~components/pages'
import { SentryCaptureException } from '~utils'

type AccountPaymentModalProps = Omit<ModalProps, 'children'>

const AccountPaymentSuccessAlert = ({ title }) => {
  return (
    <>
      <Typography.Title
        level={'h3'}
        style={{ textAlign: 'center' }}
        className={'text-center text-blue'}
      >
        {title}
      </Typography.Title>
    </>
  )
}

const AccountPaymentDeleteAlert = ({
  onConfirm,
  onCancel,
  loading,
  lastFour,
}) => {
  return (
    <>
      <Typography.Title level={'h3'} className={'text-blue mb-2'}>
        {`Are you sure you want to remove the card ending in ${lastFour}?`}
      </Typography.Title>
      <Typography.Text type={'copy'} className={'mb-6'}>
        By removing your card information your subscription will not
        successfully renew and you will lose access to premium features. If you
        dont want to lose access please add a new card. after you have removed
        your current card.
      </Typography.Text>
      <ButtonGroup>
        <Button type={'submit'} onClick={onConfirm} loading={loading}>
          Yes, remove this card
        </Button>
        <Button onClick={onCancel} theme={'inverted'} disabled={loading}>
          No
        </Button>
      </ButtonGroup>
    </>
  )
}

const AccountPaymentModal: React.FC<AccountPaymentModalProps> = ({
  onClose,
  open,
}) => {
  const { stripeStore } = useMst()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<
    | 'payment_information'
    | 'payment'
    | 'payment_confirm'
    | 'delete'
    | 'delete_confirm'
  >(
    stripeStore.customer?.readablePaymentMethod
      ? 'payment_information'
      : 'payment',
  )

  const onSubmitCallback = (success) => {
    if (success) {
      setStep('payment_confirm')
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }

  const onDeleteConfirm = async () => {
    try {
      setLoading(true)
      await stripeStore.deletePaymentMethod(
        stripeStore.customer.default_payment_method.id,
      )
      setStep('delete_confirm')
      setLoading(false)

      setTimeout(() => {
        onClose()
      }, 3000)
    } catch (e) {
      SentryCaptureException(e)
      Notify.failure('An error occurred, please try again later.')
      onClose()
    }
  }

  const onDiscardChanges = () => {
    if (stripeStore.customer?.default_payment_method) {
      setStep('payment_information')
    } else {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Transition show={step === 'payment_information'}>
        <Typography.Title level={'h3'} className={'text-blue mb-6'}>
          {'Payment Information'}
        </Typography.Title>

        <Typography.Title
          level={'h4'}
          className={'text-blue capitalize leading-4'}
        >
          {stripeStore.customer?.readablePaymentMethod?.brand}
        </Typography.Title>
        <Typography.Title level={'h4'} className={'text-blue mb-6'}>
          {stripeStore.customer?.readablePaymentMethod?.lastFour} <br />
          {stripeStore.customer?.readablePaymentMethod?.date}
        </Typography.Title>
        <Typography.Text type={'caption'} className={'mb-6'}>
          When you replace a card it will automatically remove the previous card
          info. If you just want to edit your current card go through the
          replace process.
        </Typography.Text>

        <ButtonGroup>
          <Button onClick={() => setStep('payment')}>Replace Card</Button>
          <Button onClick={() => setStep('delete')} theme={'inverted'}>
            Remove Card
          </Button>
        </ButtonGroup>
      </Transition>

      <Transition show={step === 'payment'}>
        <Typography.Title level={'h3'} className={'text-blue mb-6'}>
          {'Payment Information'}
        </Typography.Title>

        <div className={'mb-4'}>
          <PaymentForm
            white
            onSubmitCallback={onSubmitCallback}
            submitButtonTexts={{
              submit: 'Save changes',
              loading: 'Saving',
            }}
          />
        </div>

        <ButtonGroup>
          <Button onClick={onDiscardChanges} theme={'secondary'}>
            Discard changes
          </Button>
        </ButtonGroup>
      </Transition>

      <Transition show={step === 'delete'}>
        <AccountPaymentDeleteAlert
          lastFour={stripeStore.customer?.default_payment_method?.card.last4}
          loading={loading}
          onConfirm={onDeleteConfirm}
          onCancel={() => setStep('payment')}
        />
      </Transition>

      <Transition show={step === 'delete_confirm'}>
        <AccountPaymentSuccessAlert title={'Your card has been removed'} />
      </Transition>

      <Transition show={step === 'payment_confirm'}>
        <AccountPaymentSuccessAlert title={'Your card has been saved'} />
      </Transition>
    </Modal>
  )
}
export default observer(AccountPaymentModal)
