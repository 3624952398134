import React from 'react'
import { Grid, Toast, Typography } from '~components'
import css from './AccountToast.module.scss'

interface AccountToastProps {
  title: string
  subtitle?: string
  action?: {
    title: string
    onPress?: (() => void) | (() => Promise<void>)
  }
}

const AccountToast = ({ title, subtitle, action }: AccountToastProps) => (
  <button
    className={css.wrapper}
    onClick={action.onPress}
    disabled={!action.onPress}
  >
    <Toast>
      <div className={css.icon}>
        <img
          width={80}
          height={80}
          alt="Default profile pic"
          src="/img/profile-icon.png"
          className={'w-10 h-10 lg:w-13 lg:h-13'}
        />
      </div>

      <Grid className={'grid-cols-6'}>
        <div className={'lg:col-span-4 p-3'}>
          <Typography.Title level={'h3'} className={'text-blue'}>
            {title}
          </Typography.Title>
          {subtitle ? (
            <Typography.Text type={'caption'} className={'flex mt-1'}>
              {subtitle}
            </Typography.Text>
          ) : null}
        </div>
      </Grid>

      <div className={css.action}>
        <Typography.Text className={'text-black'}>
          {action.title}
        </Typography.Text>
      </div>
    </Toast>
  </button>
)

export default AccountToast
