/* eslint-disable import/no-named-as-default-member */
import api from '~api'
import type { AxiosResponse } from 'axios'
import { CookiesService, Logger } from '~utils'
import { applySnapshot, flow, getParent, types } from 'mobx-state-tree'
import {
  identifyUser,
  peopleIdentifyAndSet,
  reset,
  trackEvent,
} from '~utils/mixpanelUtils'
import {
  AuthGetToken,
  EmailVerification,
  EmailVerificationSend,
  JWTToken,
  SetPassword,
  User,
  UserNoId,
} from '~api/APITypes'
import dayjs from 'dayjs'
import { IRootStore } from '~stores/root'
import {
  IUserSubscriptionStatus,
  SnUserModel,
  UserModel,
} from '~stores/user/UserModels'

const logger = Logger('UserStore', { nsBackground: 'pink', nsColor: 'black' })

export const UserStore = types
  .model('UserStore', {
    loading: false,
    hydrated: false,
    userData: types.maybe(UserModel),
    userImage: types.maybe(types.string),
    token: types.maybe(types.string),
  })
  .actions((self) => ({
    setLoading: (state: boolean) => {
      self.loading = state
    },
    setUserData: (data: SnUserModel, can_send_email = false) => {
      self.userData = UserModel.create(data)
      peopleIdentifyAndSet(data, can_send_email)
    },
    setToken: (token: string) => {
      // Set in the local store.
      self.token = token

      // Set the token in the API Headers.
      api.setSecurityData({ token })

      // Set the token in the Cookie.
      CookiesService.setToken(token)
    },
    deleteToken: () => {
      // Remove from the local store.
      self.token = undefined

      // Delete token in the API Headers.
      api.setSecurityData(null)

      // Remove from cookies.
      CookiesService.removeToken()
    },
    getToken: (): string | undefined => {
      return CookiesService.getToken()
    },
  }))
  .actions((self) => ({
    verifyStoreToken: async () => {
      logger.log('verifyStoreToken')

      const response = await api.auth.controllersAuthGetVerifyToken({
        headers: { Authorization: 'Bearer ' + self.token },
      })

      if (response.status === 204 || response.status === 200)
        self.setToken(self.token)

      return response
    },
    verifyCookieToken: async () => {
      logger.log('verifyCookieToken')
      const cookieToken = CookiesService.getToken()
      if (!cookieToken) return

      logger.log('verifyCookieToken/cookieToken', cookieToken)
      if (cookieToken === 'undefined') {
        CookiesService.removeToken()
      }

      const response = await api.auth.controllersAuthGetVerifyToken({
        headers: { Authorization: 'Bearer ' + cookieToken },
      })

      if (response.status === 204 || response.status === 200) {
        self.setToken(cookieToken)
      }

      return response
    },
    emailVerifyToken: flow(function* (data: EmailVerification) {
      logger.log('verifyEmailToken')
      const response = yield api.email.controllersEmailPostVerification(data)
      logger.log('[verification]', response)
      return response
    }),
    emailResendVerification: flow(function* (data: EmailVerificationSend) {
      const response = yield api.email.controllersEmailPostVerificationSend(
        data,
      )
      logger.log('resend verification', response)
      return response
    }),
    fetchUserData: flow(function* () {
      const response: AxiosResponse<User> = yield api.user.controllersUserGet()
      logger.log(`[fetchUserData]`, response)
      if (response.status === 200) {
        self.setUserData(response.data)
      }
    }),
    patchUserData: flow(function* (data: UserNoId) {
      const response: AxiosResponse<User> = yield api.user.controllersUserPatch(
        data,
      )
      applySnapshot(self.userData, {
        id: response.data.id.toString(),
        ...response.data,
      })
    }),
    fetchUserImage: flow(function* () {
      try {
        const response: AxiosResponse<string> =
          yield api.user.controllersUserGetMePhoto({
            encoding: 'base64',
          })
        if (response.status === 200) {
          self.userImage = `data:image/png;base64,${response.data}`
        }
      } catch (e) {
        self.userImage = `/img/profile-pic.png`
      }
    }),
  }))
  .actions((self) => ({
    postUserImage: flow(function* (image: File) {
      try {
        const response: AxiosResponse<string> =
          yield api.user.controllersUserPostMePhoto(image)
        const success = response.status === 204 || response.status == 200
        if (success) {
          yield self.fetchUserImage()
        }
        return success
      } catch (e) {
        self.userImage = `/img/profile-pic.png`
        throw e
      }
    }),
    logIn: flow(function* (data: AuthGetToken) {
      const response: AxiosResponse<JWTToken> =
        yield api.auth.controllersAuthPostGetToken(data)
      logger.log('[login]', response)

      if (response.data.token) {
        self.setToken(response.data.token)
      }
      return response
    }),
    logInWithPhoneRequest: flow(function* (mobile: string) {
      const response: AxiosResponse<JWTToken> =
        yield api.auth.controllersAuthGetMobile(mobile)
      self.setToken(response.data.token)
      return response
    }),
    logOut: () => {
      self.deleteToken()
      self.hydrated = false
      reset() // Mixpanel reset;
      if (self.userImage) self.userImage = undefined
      if (self.userData) self.userData = undefined
    },
    authResetPasswordRequest: flow(function* (email: string) {
      return yield api.user.controllersUserPasswordReset(encodeURI(email))
    }),
    authResetPasswordConfirm: flow(function* (data: SetPassword) {
      return yield api.user.controllersUserSetPassword(data)
    }),
    hydrate: flow(function* () {
      yield self.fetchUserData()
      yield self.fetchUserImage()
      identifyUser(self.userData.id)

      self.hydrated = true
      logger.log('user data hydrated.')
    }),
  }))
  .actions((self) => ({
    signUp: flow(function* (data: UserNoId, emailData: object) {
      const response = yield api.user.userCreate(data)
      if (response.data.id) {
        self.setUserData(
          {
            id: response.data.id,
            email: data.email,
          },
          data.can_send_email,
        )
        if (typeof emailData === 'object') trackEvent('Sign Up', emailData)
        else trackEvent('Sign Up')

        return response
      }
    }),
  }))
  .views((self) => ({
    get platform() {
      if (
        self.userData?.subscription_platform === 'web' ||
        self.userData?.subscription_platform === 'promo' ||
        self.userData?.subscription_platform === null
      )
        return 'web'
      else return self.userData?.subscription_platform
    },

    get subscriptionStatus(): IUserSubscriptionStatus {
      let root: IRootStore = getParent(self)
      if (
        self.userData?.subscription_platform === 'ios' ||
        self.userData?.subscription_platform === 'android'
      ) {
        return 'app'
      } else if (!root.stripeStore.subscription) {
        /**
         * Inactive means it's a new user.
         */
        return 'inactive'
      } else if (self.userData?.canceled_at_date) {
        /**
         * If the user has a "canceled_at" means that they've had a subscription
         * before. So the flow to resubscribe is different from new users.
         */
        const now = dayjs()
        const canceledDate = dayjs(self.userData?.canceled_at_date)
        const hasExpired = canceledDate.isBefore(now)
        return hasExpired ? 'expired' : 'canceled'
      } else if (root.stripeStore.subscription) {
        /**
         * If the user has a subscription but not a `canceled_at` means that
         * they have an active subscription.
         */
        return 'active'
      }
    },
    get eligibleForPromotion() {
      if (!self.token) return true
      return (
        self.userData.days_lapsed === null || // Never had a subscription before.
        self.userData.days_lapsed >= 30 // 30 or more days without a subscription;
      )
    },
    get authorized() {
      return self.token !== undefined && self.userData !== undefined
    },
  }))
