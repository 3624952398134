export const subscriptionStatusStrings: {
  [key: string]: {
    title: string
    action: string
    subtitle?: string | ((param: string) => string)
  }
} = {
  ios: {
    title:
      'Your alive subscription is currently active through your Apple ID account.',
    action: 'Switch Subscription to Alive Directly',
  },
  android: {
    title:
      'Your alive subscription is currently active through your Google Play account.',
    action: 'Switch Subscription to Alive Directly',
  },
  inactive: {
    title: 'You currently do not have an active subscription',
    action: 'Select my subscription',
  },
  expired: {
    title: 'You currently do not have an active subscription',
    action: 'Select my subscription',
  },
  canceled: {
    title: 'Your subscription has been canceled and not set to renew.',
    subtitle: (date: string) =>
      `You will still have access to the app through ${date}`,
    action: 'Resubscribe',
  },
}

export const subscriptionSuccessModal = {
  default: {
    title: 'Your subscription is all set!',
    content: "Don't forget to cancel your subscription on your Platform.",
    disclaimer:
      'If you don’t cancel your subscription with your Platform you will be double charged.',
  },
  android: {
    title: 'Your subscription is all set!',
    content:
      "Don't forget to cancel your subscription on your Google account. Tap the open button to be redirected to your Google Play settings.",
    disclaimer:
      'If you don’t cancel your subscription with Google you will be double charged.',
    link: {
      title: 'Open Settings',
      url: 'https://play.google.com/store/account/subscriptions',
    },
  },
  ios: {
    title: 'Your subscription is all set!',
    content:
      "Don't forget to cancel your subscription on your Apple account. Tap the open button to be redirected to your Apple settings.",
    disclaimer:
      'If you don’t cancel your subscription with Apple you will be double charged.',
    link: {
      title: 'Open Settings',
      url: 'itms-apps://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions',
    },
  },
}
