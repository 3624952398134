import React, {
  Children,
  ButtonHTMLAttributes,
  forwardRef,
  useMemo,
} from 'react'
import { LoadingIndicator } from '~components'
import classNames from 'classnames/bind'
import css from './Button.module.scss'

const cx = classNames.bind(css)

export const ButtonGroup: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const childrenCount = useMemo(() => Children.count(children), [children])
  if (childrenCount <= 1)
    return <div className={className ? className : ''}>{children}</div>
  return (
    <div className={className ? className : ''}>
      {Children.map(children, (child, index) => {
        if (index === childrenCount - 1) return child
        else return <div className={'mb-2'}>{child}</div>
      })}
    </div>
  )
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  secondary?: boolean
  inverted?: boolean
  warning?: boolean
  theme?: 'default' | 'secondary' | 'inverted' | 'warning'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      loading = false,
      secondary = false,
      inverted = false,
      warning = false,
      theme = 'default',
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled || loading}
        className={cx(className, {
          default: true,
          inverted: inverted || theme === 'inverted',
          secondary: secondary || theme === 'secondary',
          warning: warning || theme === 'warning',
        })}
        {...rest}
      >
        {children}{' '}
        {loading ? (
          <LoadingIndicator white size={'sm'} className={'ml-2'} />
        ) : null}
      </button>
    )
  },
)

export default Button
