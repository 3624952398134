import React, { useState } from 'react'
import { useMst } from '~stores'
import { AccountLoginModal, AccountPageCard } from '~components/pages'
import { LoginIcon } from '~svg'
import { observer } from 'mobx-react'

const AccountCardLogin = ({ className = '' }: { className?: string }) => {
  const { userStore } = useMst()
  const [loginOpen, setLoginOpen] = useState(false)

  return (
    <>
      {loginOpen ? (
        <AccountLoginModal
          open={loginOpen}
          onClose={() => setLoginOpen(false)}
        />
      ) : null}
      <AccountPageCard.Box
        title={'Account log in'}
        className={`col-span-3 ${className}`}
        actions={[
          {
            label: 'Edit',
            onClick: () => setLoginOpen(true),
          },
        ]}
        empty={{
          Icon: <LoginIcon />,
          text: 'Please add your email and password.',
        }}
      >
        {userStore.userData?.email ? (
          <AccountPageCard.Text>
            {userStore.userData?.email} <br />
            ********
          </AccountPageCard.Text>
        ) : null}
      </AccountPageCard.Box>
    </>
  )
}

export default observer(AccountCardLogin)
