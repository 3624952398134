import React, { useState } from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { ProfileIcon } from '~svg'
import { AccountPageCard, AccountProfileModal } from '~components/pages'

const AccountCardProfile = ({ className = '' }: { className?: string }) => {
  const { userStore } = useMst()
  const [profileOpen, setProfileOpen] = useState(false)

  return (
    <>
      {profileOpen ? (
        <AccountProfileModal
          open={profileOpen}
          onClose={() => setProfileOpen(false)}
        />
      ) : null}

      <AccountPageCard.Box
        title={'Profile'}
        className={`col-span-3 ${className}`}
        actions={[
          {
            label: 'Edit',
            onClick: () => setProfileOpen(true),
          },
        ]}
        empty={{
          text: 'Please add your name and phone number',
          Icon: <ProfileIcon />,
          actions: [
            {
              label: 'Edit',
              onClick: () => setProfileOpen(true),
            },
          ],
        }}
      >
        {userStore.userData ? (
          <AccountPageCard.Text>
            {`${userStore.userData?.first_name} ${userStore.userData?.last_name}`}
            <br />
            {userStore.userData?.mobile
              ? userStore.userData?.mobileWithFormat
              : null}
          </AccountPageCard.Text>
        ) : null}
      </AccountPageCard.Box>
    </>
  )
}

export default observer(AccountCardProfile)
