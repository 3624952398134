import React from 'react'
import { Input, InputProps } from './Input'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'

export interface ControlledInputProps<TInput extends string, TOutput>
  extends Omit<ControllerProps<any, any>, 'render'> {
  name: string
  inputProps?: Omit<InputProps, 'name'>
  transform?: {
    input?: (value: string) => string
    output?: (event: React.ChangeEvent<HTMLInputElement>) => string
  }
}

export const ControlledInput = <TInput extends string, TOutput>({
  name,
  transform,
  inputProps,
  defaultValue = '',
  ...restController
}: ControlledInputProps<TInput, TOutput>) => {
  const { control } = useFormContext()

  return (
    <Controller
      {...restController}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <Input
          {...field}
          {...inputProps}
          id={name}
          error={error}
          value={
            transform && transform.input
              ? transform?.input(field.value)
              : field.value
          }
          onChange={(e) => {
            if (transform)
              field.onChange(
                transform.output(e as React.ChangeEvent<HTMLInputElement>),
              )
            else field.onChange(e)
          }}
        />
      )}
    />
  )
}

export default ControlledInput
