import React from 'react'
import Script from 'next/script'
import { GOOGLE_PIXEL_ID } from '~utils'

const GTM = () => (
  <Script
    id={'gtm-id'}
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GOOGLE_PIXEL_ID}');
      `,
    }}
  />
)

export default GTM