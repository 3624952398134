import React from 'react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import type { FormProps } from '~components'
import {
  ButtonGroup,
  Form,
  FormError,
  Link,
  PasswordInput,
  Button,
  ControlledInput as Input,
  Typography,
} from '~components'

const LoginForm = ({ onSubmit, loading, error, ...context }: FormProps) => {
  const router = useRouter()
  return (
    <Form {...context} onSubmit={onSubmit}>
      <Input
        name={'email'}
        inputProps={{
          label: 'Your Email',
          placeholder: 'mail@example.com',
          wrapperClassName: 'mb-3',
        }}
        transform={{
          output: (e) => {
            return e.target.value.toLowerCase()
          },
        }}
      />
      <PasswordInput
        name={'password'}
        inputProps={{
          type: 'password',
          label: 'Password',
          placeholder: '********',
          wrapperClassName: 'mb-2',
        }}
      />
      <Typography.Text type={'copy'} className={'link mb-4'}>
        <Link href="/password/forgot">Forgot Password?</Link>
      </Typography.Text>

      {error ? <FormError className={'mb-5'} error={error} /> : ''}

      <ButtonGroup>
        <Button loading={loading} type={'submit'}>
          Log In
        </Button>
        <Button
          theme={'inverted'}
          disabled={loading}
          onClick={() => router.push('/login/phone')}
        >
          Log In with phone
        </Button>
      </ButtonGroup>
    </Form>
  )
}

export default observer(LoginForm)
