import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { AliveLogo } from '~svg'
import Menu from '~svg/Menu.svg'
import Close from '~svg/CloseMenu.svg'
import { Button, Link } from '~components'
import { ALIVE_MARKETING_URL, SentryCaptureException } from '~utils'
import classNames from 'classnames/bind'
import css from './Navigation.module.scss'

const cx = classNames.bind(css)

interface NavigationProps {
  authorized: boolean
  onLogOut?: () => void
  RightNode?: React.ReactNode
}

const blueBgPaths = ['/account', '/payment-history']

const Navigation: React.FC<NavigationProps> = ({
  RightNode,
  authorized = false,
  onLogOut,
}) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const onRouterStart = () => {
      setOpen(false)
    }
    router.events.on('routeChangeStart', onRouterStart)
  }, [])

  const showLoginButton = useMemo(() => {
    const paths = ['/login', '/login/phone']
    return !paths.includes(router.pathname)
  }, [router.pathname])

  const onLogout = async () => {
    try {
      if (onLogout) {
        await onLogOut()
        router.push('/login')
      }
    } catch (e) {
      SentryCaptureException('Logout', e)
    }
  }

  const onLogin = async () => {
    router.push('login')
  }

  const toggleOpen = () => {
    setOpen((isopen) => !isopen)
  }
  return (
    <header
      className={cx({
        open,
        default: true,
        logged: blueBgPaths.includes(router.pathname),
      })}
    >
      <div className={cx({ logo__wrapper: true, open })}>
        <div className={css.logo}>
          <Link href={authorized ? '/account' : ALIVE_MARKETING_URL}>
            <AliveLogo />
          </Link>
        </div>

        <button className={css.toggle} onClick={toggleOpen}>
          {open ? <Close id={'close'} /> : <Menu id={'open'} />}
        </button>
      </div>

      <nav className={cx({ nav: true, open })}>
        <ul>
          <li>
            <a href={`${ALIVE_MARKETING_URL}/programs`}>Programs</a>
          </li>
          <li>
            <a target={'_blank'} href={`${ALIVE_MARKETING_URL}/support`}>
              Support
            </a>
          </li>
          <li>
            <a href={'https://shop.aliveapp.co'}>Shop Apparel</a>
          </li>
          {RightNode ? <li>{RightNode}</li> : null}
          {authorized ? (
            <li className={css.onlyDesk}>
              <a onClick={onLogout} className={'h4 clickable'}>
                Log Out
              </a>
            </li>
          ) : showLoginButton ? (
            <li className={css.onlyDesk}>
              <Link href={'/login'} className={'h4'}>
                Log In
              </Link>
            </li>
          ) : null}
        </ul>
      </nav>

      <div className={cx({ nav__backdrop: true, open })}>
        {authorized ? (
          <Button onClick={onLogout}>Log Out</Button>
        ) : showLoginButton ? (
          <Button onClick={onLogin} className={'h4'}>
            Log In
          </Button>
        ) : null}
      </div>
    </header>
  )
}

export default Navigation
