import React from 'react'
import { Checkbox } from '~svg'
import { Typography } from '~components'
import classNames from 'classnames/bind'
import css from './PricingTable.module.scss'

const cx = classNames.bind(css)

export interface PricingTableItemProps {
  title: string
  subtitle: string
  checked?: boolean
  clickable?: boolean
  banner?: string
  bannerCrossed?: boolean
  promotion?: string
}

const PricingTableItem = ({
  title,
  subtitle,
  checked = false,
  clickable = true,
  banner,
  bannerCrossed = false,
  promotion,
}: PricingTableItemProps) => {
  return (
    <div
      className={cx({
        item_container: true,
        clickable: clickable && !checked,
        checked,
      })}
    >
      {promotion ? (
        <div className={css.item_notification}>
          <Typography.Title className={'text-black'} level={'h4'}>
            {promotion}
          </Typography.Title>
        </div>
      ) : null}
      <div className={css.item_wrapper}>
        {banner ? (
          <div className={cx({ item_discount: true, bannerCrossed })}>
            <Typography.Title className={'text-white'} level={'h4'}>
              {banner}
            </Typography.Title>
          </div>
        ) : null}
        {checked ? (
          <div className={css.checkbox}>
            <Checkbox />
          </div>
        ) : null}
        <Typography.Title
          level={'h3'}
          className={css.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Typography.Text className={'text-blue'} type={'caption'}>
          {subtitle}
        </Typography.Text>
      </div>
    </div>
  )
}

export default PricingTableItem
