import { FC, DetailedHTMLProps, HTMLAttributes } from 'react'
import { AnyTag, PropsOf } from '~types'

interface TitleProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  level?: 'h1' | 'h2' | 'h3' | 'h4'
  lineHeight?: number
}

const Title: FC<TitleProps> = ({
  children,
  level: TitleLevel = 'h1',
  className,
  lineHeight,
  ...rest
}) => {
  return (
    <TitleLevel
      className={`${TitleLevel} ${className ? className : ''}`}
      {...rest}
      style={{ lineHeight, ...rest.style }}
    >
      {children}
    </TitleLevel>
  )
}

function Text<Tag extends AnyTag>(
  props: {
    as?: Tag
    type?: 'copy' | 'caption' | 'disclaimer' | 'label'
  } & PropsOf<Tag>,
): JSX.Element

function Text({
  children,
  type = 'copy',
  as: Wrapper = 'div',
  className,
  ...rest
}: any) {
  return (
    <Wrapper className={`${type} ${className ? className : ''}`} {...rest}>
      {children}
    </Wrapper>
  )
}

const Typography = {
  Title,
  Text,
}

export default Typography
