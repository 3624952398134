import React, {Dispatch, useEffect} from 'react'
import {RadioGroup} from '@headlessui/react'
import {PricingTableItemRadio} from '~components'
import {FieldError} from 'react-hook-form'
import {IStripePrice} from '~stores'
import {observer} from "mobx-react";

export type PricingTableKeys = 'year' | 'month'

export interface PricingTableRadioGroupProps {
    value: string
    onChange:
        | ((priceKey: string) => void)
        | ((priceKey: string) => Promise<void>)
        | Dispatch<React.SetStateAction<string>>
    error?: FieldError
    prices: Array<IStripePrice>
}

const PricingTableRadioGroup: React.FC<PricingTableRadioGroupProps> = ({
                                                                           value,
                                                                           onChange,
                                                                           error,
                                                                           prices,
                                                                       }) => {
    useEffect(() => {
        if (error) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [error])

    return (
        <RadioGroup value={value} onChange={onChange}>
            {prices.map((item, index) => {
                return (
                    <PricingTableItemRadio
                        value={item.id}
                        key={`PricingTableItemRadio-${index}`}
                        {...item.readablePricing}
                    />
                )
            })}
        </RadioGroup>
    )
}

export default observer(PricingTableRadioGroup)
