import React, { useState } from 'react'
import { useMst } from '~stores'
import { Logger } from '~utils'
import { Notify } from 'notiflix'
import { observer } from 'mobx-react'
import * as Sentry from '@sentry/react'
import { Button, Typography, FormError } from '~components'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import classNames from 'classnames'
import { IUserSubscriptionPlatform } from '~stores/user'

const logger = Logger('PaymentForm', { nsBackground: 'yellow' })

interface PaymentFormProps {
  white?: boolean
  submitButtonTexts?: {
    submit: string
    loading: string
  }
  beforeSubmit?: () => void
  onSubmitCallback?: (
    success: boolean,
    previousSubscriptionPlatform: IUserSubscriptionPlatform | null,
  ) => void
}

const PaymentForm = ({
  beforeSubmit,
  onSubmitCallback,
  white = true,
  submitButtonTexts = {
    submit: 'Pay',
    loading: 'Paying',
  },
}: PaymentFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const { userStore, stripeStore } = useMst()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<{ message: string }>()

  const onSubmit = async (event) => {
    event.preventDefault()

    // Taken from https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-submit-payment
    setError(null)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setLoading(true)
    if (beforeSubmit) await beforeSubmit()

    const previousSubscriptionPlatform =
      userStore.userData?.subscription_platform?.concat() ||
      (null as IUserSubscriptionPlatform)

    const confirmSetup = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    logger.log('[confirmSetup]', confirmSetup)

    if (confirmSetup.error) {
      setLoading(false)
      setError({ message: confirmSetup.error.message })
      Notify.failure('Something went wrong.')

      if (onSubmitCallback)
        onSubmitCallback(
          false,
          previousSubscriptionPlatform as IUserSubscriptionPlatform,
        )
      return
    } else {
      /**
       * Update the customer so we can set the payment method as the 'default'
       */
      const patchCustomer = await stripeStore.patchCustomer(
        confirmSetup.setupIntent.payment_method,
      )
      logger.log('[patchCustomer]', patchCustomer)

      /**
       * Update the billing Address before, so it matches in case we need a subscription.
       */
      const billingAddress = await stripeStore.putBillingAddress(
        patchCustomer.customer.default_payment_method.billing_details.address,
      )
      logger.log('[billingAddress]', billingAddress)

      if (onSubmitCallback) {
        await onSubmitCallback(
          true,
          previousSubscriptionPlatform as IUserSubscriptionPlatform,
        )
      }
    }
  }

  return (
    <form
      onSubmit={onSubmit}
      className={classNames({ 'bg-white py-3 rounded-16px': white })}
    >
      <div className={'lg:col-span-3 mb-4 lg:mb-6'}>
        {stripeStore.elementsClientSecret ? <PaymentElement /> : null}
      </div>
      <FormError error={error} />
      <Button type={'submit'} loading={loading}>
        {loading ? submitButtonTexts.loading : submitButtonTexts.submit}
      </Button>
      <Typography.Text
        as={'div'}
        type={'caption'}
        className={'text-black text-center mt-3 w-full'}
        style={{ textAlign: 'center' }}
      >
        Applicable VAT, sales or other taxes may apply. Cancel anytime.
      </Typography.Text>
    </form>
  )
}
export default observer(PaymentForm)
