import React from 'react'
import Splash from './Splash/Splash'
import Footer from './Footer/Footer'
import Navigation from './Navigation/Navigation'

interface LayoutProps {
  loading: boolean
  authorized: boolean
  onLogOut?: () => void
}

const Layout: React.FC<LayoutProps> = ({
  children,
  loading,
  authorized,
  onLogOut,
}) => {
  return (
    <>
      <Navigation authorized={authorized} onLogOut={onLogOut} />
      <div className={'min-h-screen'}>{!loading ? children : null}</div>
      <Splash show={loading} />
      <Footer />
    </>
  )
}

export default Layout
