import React from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { formatDateNumber } from '~utils'
import { AccountPageCard } from '~components/pages'
import { BillingIcon } from '~svg'

interface AccountPagePaymentHistoryCardProps {
  className?: string
}

const AccountCardPaymentHistory: React.FC<AccountPagePaymentHistoryCardProps> =
  ({ className = '' }) => {
    const router = useRouter()
    const { stripeStore } = useMst()

    return (
      <AccountPageCard.Box
        title={'Payment History'}
        className={`col-span-3 ${className}`}
        empty={{
          Icon: <BillingIcon />,
          text: 'You don’t have any billing history.',
        }}
        actions={[
          {
            label: 'View',
            onClick: () => router.push('/payment-history'),
          },
        ]}
      >
        {stripeStore.hasInvoices ? (
          <AccountPageCard.Text>
            Last Invoice <br />
            {formatDateNumber(stripeStore.lastInvoice?.created, true)}
          </AccountPageCard.Text>
        ) : null}
      </AccountPageCard.Box>
    )
  }

export default observer(AccountCardPaymentHistory)
