import React, { Dispatch, SetStateAction } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormError, InputLabel, Select } from '~components'
import type { ControlledInputProps } from '~components'
import { default as LibPhoneInput } from 'react-phone-number-input/input'
import { CountryCode } from 'libphonenumber-js/min'
import { phone_codes } from '~data'

import classNames from 'classnames/bind'
import css from './PhoneInput.module.scss'
import inputCSS from './Input.module.scss'

const phoneCX = classNames.bind(inputCSS)

const optionsList = Object.keys(phone_codes).map((key) => {
  const code = phone_codes[key]
  const label = `${key}`
  const value = `${key}`
  const realValue = code

  return { id: key, label, value, realValue }
})

interface PhoneInputProps extends ControlledInputProps<string, string> {
  countryCode: CountryCode
  setCountryCode: Dispatch<SetStateAction<CountryCode>>
}

const PhoneInput = ({
  inputProps,
  countryCode,
  setCountryCode,
  ...props
}: PhoneInputProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      {...props}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <InputLabel
            id={inputProps.id}
            label={inputProps.label}
            error={!!error}
          />
          <div className={'flex flex-row mb-2'}>
            <Select
              theme={inputProps.theme}
              value={countryCode}
              options={optionsList}
              onChange={(e) => setCountryCode(e as CountryCode)}
              wrapperClassName={css.select}
            />
            <div className={'w-full'}>
              <LibPhoneInput
                {...field}
                country={countryCode}
                className={`
                                    ${phoneCX({
                                      error,
                                      input: true,
                                      white: inputProps.theme === 'white',
                                    })} 
                                    mb-1
                                `}
                placeholder={inputProps.placeholder}
              />
              <FormError error={error} />
            </div>
          </div>
        </>
      )}
    />
  )
}
export default PhoneInput
