import React from 'react'
import { getValidationSchema } from '~utils'
import { requiredEmail } from '~validations'
import {
  Button,
  Form,
  ControlledInput as Input,
  useCustomForm,
  FormProps,
} from '~components'

const formSchema = getValidationSchema({
  email: requiredEmail,
})

interface ForgotPasswordFormValues {
  email: string
}

const ForgotPasswordForm = ({
  error,
  loading,
  onSubmit,
}: Pick<FormProps, 'error' | 'loading' | 'onSubmit'>) => {
  const context = useCustomForm<ForgotPasswordFormValues>({
    schema: formSchema,
  })

  return (
    <Form
      error={error}
      {...context}
      onSubmit={onSubmit}
      className={'col-start-4 col-span-6'}
    >
      <Input
        name={'email'}
        inputProps={{
          label: 'Your Email',
          placeholder: 'mail@example.com',
          wrapperClassName: 'mb-3',
          theme: 'white',
        }}
        transform={{
          output: (e) => {
            return e.target.value.toLowerCase()
          },
        }}
      />
      <Button loading={loading}>
        {loading ? 'Sending' : 'Send reset link'}
      </Button>
    </Form>
  )
}

export default ForgotPasswordForm
