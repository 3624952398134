import React, { useState } from 'react'
import { AccountPageCard, AccountPaymentModal } from '~components/pages'
import { PaymentIcon } from '~svg'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'

const AccountCardPaymentInformation = ({
  className = '',
}: {
  className?: string
}) => {
  const router = useRouter()
  const { stripeStore } = useMst()
  const [paymentOpen, setPaymentOpen] = useState(false)

  return (
    <>
      {paymentOpen ? (
        <AccountPaymentModal
          open={paymentOpen}
          onClose={() => setPaymentOpen(false)}
        />
      ) : null}
      <AccountPageCard.Box
        title={'Payment information'}
        className={`col-span-3 ${className}`}
        actions={[
          {
            label: 'Edit',
            onClick: stripeStore.subscription
              ? () => setPaymentOpen(true)
              : () => router.push('/payment'),
          },
        ]}
        empty={{
          text: 'Please add your payment information.',
          Icon: <PaymentIcon />,
          actions: [
            {
              label: 'Add',
              onClick: stripeStore.subscription
                ? () => setPaymentOpen(true)
                : () => router.push('/payment'),
            },
          ],
        }}
      >
        {stripeStore.customer?.readablePaymentMethod ? (
          <>
            <AccountPageCard.Text>
              {stripeStore.customer?.readablePaymentMethod.lastFour}
            </AccountPageCard.Text>
            <AccountPageCard.Text>
              {stripeStore.customer?.readablePaymentMethod.date}
            </AccountPageCard.Text>
          </>
        ) : null}
      </AccountPageCard.Box>
    </>
  )
}

export default observer(AccountCardPaymentInformation)
