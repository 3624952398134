import { SVGComponent } from '~types'

const LockIcon = (props: SVGComponent) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect y="4.6665" width="10" height="9.33333" rx="2" fill="#0D66FC" />
      <rect
        x="2.53906"
        y="1"
        width="4.92308"
        height="5.77778"
        rx="1"
        stroke="#0D66FC"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default LockIcon
