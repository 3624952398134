import React from 'react'
import { observer } from 'mobx-react'
import { RadioGroup } from '@headlessui/react'
import PricingTableItem, { PricingTableItemProps } from './PricingTableItem'

export interface PricingTableItemRadioProps extends PricingTableItemProps {
  value: string
}

const PricingTableItemRadio: React.FC<PricingTableItemRadioProps> = ({
  value,
  checked,
  ...restProps
}) => {
  return (
    <RadioGroup.Option value={value} className={'outline-0 outline-hidden'}>
      {({ checked }) => <PricingTableItem {...restProps} checked={checked} />}
    </RadioGroup.Option>
  )
}

export default observer(PricingTableItemRadio)
